export const loans = {
  hideDetails: 'Hide details',
  showDetails: 'See details',
  paymentProgress: 'Payment progress',
  paymentsLeft: '{payments, plural, =0 {no payments} =1 {1 payment} other {# payments}} left',
  transactionID: 'Transaction ID',
  transactionAmount: 'Transaction Amount',
  transactionAmt: 'Transaction Amt',
  transactionType: 'Transaction Type',
  dueDate: 'Due date',
  status: 'Status',
  currentBalance: 'Current balance',
  loanID: 'Loan number',
  loanType: 'Finance type',
  customerNotAvailable: 'Customer name not available',
  companyNotAvailable: 'Company name not available',
  notAvailable: 'Not available',
  notAvailableNA: 'N/A',
  expiryDate: 'Expiry date',
  nextPayment: 'Next payment {current}/{total}',
  nextPaymentAmount: 'Next payment amount',
  failedSettingNextPayment: "Something went wrong with retrieving {name}'s loan payment",
  activeLoans: 'Active loans',
  activeDeals: 'Active deals',
  transactions: 'Transactions',
  completed: 'completed',
  contact: 'Contact',
  cancel: 'Cancel',
  assetType: 'Asset type',
  assetValue: 'Asset value',
  chargeDate: 'Charge date',
  assetDetails: 'Asset Details',
  vehicleDetails: 'Vehicle Details',
  paymentSchedule: 'Payment Schedule',
  dealerName: 'Dealer name',
  contactDetails: 'Contact details',
  groupLabel: 'Show',
  applicationStatusLabel: 'Filter by',
  groupByNone: 'Flat list',
  groupByClient: 'Grouped by client',
  sortByNameAsc: "Clients' names: A→Z",
  sortByNameDesc: "Clients' names: Z→A",
  sortByDateAsc: 'Charge date: soonest first',
  sortByDateDesc: 'Charge date: latest first',
  sortLabel: 'Sort by',
  sortByChargeDate: 'Charge date',
  loanStatusProposal: 'proposal',
  loanStatus: 'Application status: {status}',
  stepCounter: 'Step {index}',
  applyingOnClientBehalf: "I'm applying for a loan on behalf of a client",
  currency: 'Currency',
  vatExempt: "I'm VAT exempt",
  upfrontDeposit: 'Upfront deposit',
  upfrontDepositLong: 'Deposit (A 10% deposit/trade in is recommended.)',
  estimatedTradeInValue: 'Estimated trade in value',
  estimatedTradeInValueShort: 'Trade in value',
  settlePreviousLoanBackendRequired: 'Do we need to settle a previous loan backed by the asset?',
  financeAmount: 'Finance amount',
  monthlyPayments: 'Monthly payments',
  terms: 'Term <help>(months)</help>',
  totalCost: 'Total cost',
  totalNetCost: 'Total cost',
  additionalVatAmount: 'Excluding VAT',
  prev: 'Prev',
  next: 'Next',
  yes: 'Yes',
  no: 'No',
  leasing: 'Business Leasing',
  ucgsLeasing: 'UCGS Leasing',
  hirePurchaseOption: 'Hire Purchase',
  hirePurchase: 'Business Hire Purchase',
  ucgsHirePurchase: 'UCGS Hire Purchase',
  personalMotorFinance: 'Personal Motor Finance',
  assetFinance: 'Asset Finance',
  commercialRealEstate: 'Commercial Real Estate',
  invoiceDiscounting: 'Invoice Discounting',
  saveForLater: 'Save for later',
  settlement: 'Settlement',
  requestACallback: 'Request a callback',
  applyNow: 'Start Application',
  financeCalculator: 'Finance Calculator',
  show: 'Apply Now',
  close: 'Close',
  noActiveLoans: 'You have no active loans',
  make: 'Make',
  model: 'Model',
  registrationNumber: 'Registration Number',
  registrationNumberOptional: 'Registration Number (If Known)',
  makeAndModel: 'Make & Model',
  chassisNumber: 'Chassis Number',
  fuelType: 'Fuel Type',
  engineCapacity: 'Engine Capacity',
  transmission: 'Transmission',
  colour: 'Colour',
  supplier: 'Supplier',
  yearOfManufacture: 'Year of manufacture',
  yearOfRegistration: 'Year of Registration',
  numberOfAssets: 'How many items?',
  assetCost: 'Asset cost',
  condition: 'Condition',
  new: 'New',
  used: 'Used',
  searchInDatabase: 'Search in database',
  dealer: 'Dealer/Supplier (If Known)',
  dealerOrSupplier: 'Dealer/Supplier',
  mileage: 'Mileage',
  addDealer: 'Add Dealer',
  contactName: 'Contact Name',
  contactNumber: 'Contact Number',
  address: 'Address',
  noOptions: 'Start typing to search a dealer',
  applications: 'Applications',
  continue: 'Continue',
  delete: 'Delete',
  currentState: 'Current state',
  monthlyRepayment: 'Monthly repayment',
  term: 'Term',
  loanId: 'Loan ID',
  applicationNumber: 'Application Number',
  noAssetDetails: 'No asset details yet.',
  type: 'Type',
  updated: 'Updated',
  termMonth: '{term, plural, =1 {1 month} other {# months}}',
  loanAmount: 'Loan amount',
  asset: 'Asset',
  vehicle: 'Vehicle',
  externalAuthorization: 'External Authorization',
  connect: 'Connect',
  waitingForUnderwriter: 'Waiting for Underwriter decision ',
  summaryOfLoanApplication: 'Summary of Loan Application ',
  summaryOfApplication: 'Summary of Application ',
  waitingForUnderwriterDesc:
    'Thank you for your application which is now being processed.\n' +
    'Our team will review this application and will be in touch with you shortly if further information is required.',
  backToDashboard: 'Back to dashboard',
  updatedProposal: 'Updated proposal',
  exclVat: '(excl. VAT)',
  costExclVat: 'Cost (excl. VAT)',
  forMonths: '{term, plural, =1 {for 1 month} other {for # months}}',
  submit: 'Submit',
  submitApplication: 'Next',
  reviewEnquiry: 'Submit Enquiry',
  sendEmail: 'Send email with link',
  accept: 'Accept',
  proceed: 'Proceed',
  reject: 'Reject',
  proposalRejected: 'The proposal offer has been rejected by you.',
  proposalAccepted: 'The proposal offer has been accepted by you.',
  directors: 'Directors',
  whichDirectors: 'Which of the following directors will sign the agreement?',
  confirmation: 'Confirmation:',
  confirmationDescription:
    'Under the Credit Reporting Act 2013 lenders are required to provide personal and credit information for credit applications and credit agreements of €500 and above to the Central Credit Register. This information will be held on the Central Credit Register and may be used by other lenders when making decisions on your credit applications and credit agreements.',
  acceptLoanConfirmation:
    'By ticking this box you are acknowledging that you are submitting an application request to Capitalflow Commercial Finance.',
  enterEmail: 'Enter email address',
  commission: 'Commission',
  UCGSCheck: 'Ukraine Credit Guarantee Scheme (UCGS)',
  UCGSCode: 'UCGS Eligibility Code',
  brokerFee: 'Broker Fee',
  skip: 'Skip',
  loading: 'Loading...',
  startDate: 'Start date',
  uploadDocuments: 'Upload documents',
  uploadSupportingDocuments: 'Upload supporting documents',
  bankStatements: 'Bank Statements',
  bankStatementsPersonalMotorFinance:
    'Bank Statements (Mandatory for finance amount of €30,000 and above)',
  taxClearance: 'Tax Clearance Certificate',
  statementOfAffairs: 'Statement of Affairs',
  accounts: 'Accounts',
  invoice: 'Invoice',
  payslips: 'Payslips',
  payslipPersonalMotorFinance: 'Payslip (Upload a recent payslip within the last 3 months)',
  ucgsEligibilityDocument: 'UCGS Eligibility Document',
  proposalForms: 'Proposal Forms',
  uploadDocumentsMessageAboveNetCost:
    'We will need additional supporting information such as recent bank statements, and we may need tax clearance certificate etc. If requested during the application process, these can be uploaded here.',
  uploadDocumentsMessageBelowNetCost:
    'We may need additional supporting information such as recent bank statements, tax clearance certificate etc. If requested during the application process, these can be uploaded here.',
  uploadDocumentsMessage2:
    'To help us arrive at a decision more quickly, please upload your last set of financial accounts and tax clearance certificate.',
  uploadDocumentsMessagePersonalMotorFinance:
    'We will need a recent payslip and may need a bank statement for your finance application, which can be uploaded below.',
  dragFiles: 'Drag and drop or browse files from you computer',
  fileFormatsRestriction:
    'Types of files accepted include: PDF, CSV, TXT, Image Files (including JPEG & PNG), XML and Microsoft Office.',
  fileSizeRestriction: 'Each file should be of 25MB or less in size',
  dropFilesHere: 'Drop your files here',
  save: 'Save',
  saved: 'Saved',
  uploadMore: 'Upload more documents',
  uploadedFiles: 'Uploaded files',
  importFromComputer: 'import from computer',
  reasonForPurchase: 'Reason for Purchase?',
  reasonForPurchaseHelp:
    'Tell us a little bit about your plans for the purchase - replacing an asset/expansion etc',
  reasonForPurchaseHelpPMF: 'Tell us a little bit about your plans for the purchase',
  reasonForPurchaseExample:
    'Replacement of asset, new asset for new contracts, increased capacity or work etc.',
  reasonForPurchasePMFExample: 'Replacement vehicle, purchase of new vehicle etc.',
  companyType: 'Company type',
  companyName: 'Company name',
  companyNumber: 'Company number',
  edit: 'Edit',
  company: 'Company',
  saveClient: 'Save',
  UCGS: 'Are you applying for the Ukraine Credit Guarantee Scheme',
  trading: 'Trading',
  countryBusiness: 'Country of main business operations',
  companyAddress: 'Company Address',
  motorFinanceAddress: 'Address',
  address1: 'Address Line 1',
  address2: 'Address Line 2',
  address3: 'Address Line 3',
  previousAddress1: 'Previous Address Line 1',
  previousAddress2: 'Previous Address Line 2',
  previousAddress3: 'Previous Address Line 3',
  address4: 'Address Line 4',
  country: 'Country',
  county: 'County',
  previousCountry: 'Previous Country',
  previousCounty: 'Previous County',
  naceCodePicker: 'Choose NACE code',
  naceCode: 'NACE code',
  noOptionsCompany: 'Start typing to search a company',
  noOptionsNaceCode: 'Start typing to search a nace code',
  customerBackground: 'Customer background',
  contacts: 'Contacts',
  addContact: 'Add Another Contact',
  firstName: 'First Name',
  lastName: 'Last Name',
  dateOfBirth: 'Date of Birth',
  ppsn: 'PPS Number',
  removeContact: 'Remove Contact',
  principalContact: 'Principal Contact',
  createdDate: 'Date of Application',
  yourDetails: 'Your Details',
  financeDetails: 'Finance Details',
  all: 'All',
  draft: 'Draft',
  proposal: 'Proposal',
  underwriting: 'Underwriting',
  payOut: 'Pay Out',
  accepted: 'Accepted',
  needMoreInformation: 'Need More Information',
  declined: 'Declined',
  noApplication: 'You have no applications',
  readyForProposal: 'Ready for Proposal',
  createdBy: 'Created by',
  enquiries: 'Enquiries',
  noEnquiries: 'You have no active enquiries',
  documentsIssued: 'Documents Issued',
  documentsReceived: 'Documents Received',
  lookupDealer: 'Lookup Dealer',
  website: 'website',
  employmentStatus: 'Employment Status',
  employerName: 'Employer Name',
  timeWithEmployerYears: 'Time with Employer (Years)',
  timeWithEmployerMonths: 'Time with Employer (Months)',
  previousEmployer: 'Previous Employer',
  timeWithPreviousEmployerYears: 'Time with Previous Employer (Years)',
  timeWithPreviousEmployerMonths: 'Time with Previous Employer (Months)',
  netSalaryPerMonth: 'Net Salary per Month',
  otherIncomePerMonth: 'Other Net Income Per Month',
  MortgageRentPerMonth: 'Mortgage/Rent per Month',
  otherBorrowingsPerMonth: 'Other Borrowings per Month',
  eircode: 'Eircode',
  previousEircode: 'Previous Eircode',
  timeAtAddressYears: 'Time at Address (Years)',
  timeAtAddressMonths: 'Time at Address (Months)',
  residentialStatus: 'Residential Status',
  occupation: 'Occupation',
  selectCountry: 'Select Country',
  purchaseTimeline: 'Purchase Timeline',
  applicationOverview: 'Application Overview',
  forename: 'Forename',
  surname: 'Surname',
  maidenName: 'Maiden Name (If Different From Surname)',
  gender: 'Gender',
  nationality: 'Nationality',
  dependents: 'Dependents',
  maritalStatus: 'Marital Status',
  yearOfRegistrationTooltip:
    'Capitalflow can finance vehicles up to 12 years of age at the end of the term.',
  pmfWarningMessage1: 'Warning: You will not own these goods until the final payment is made.',
  pmfWarningMessage2:
    'Warning: You may have to pay charges if you pay off a hire purchase agreement or consumer hire agreement early.',
  pmfWarningMessage3:
    'Warning: If you do not meet the repayments on your hire purchase agreement or consumer hire purchase agreement, your account will go into arrears. This may affect your credit rating, which may limit your ability to access credit, a hire-purchase agreement, a consumer-hire agreement or a BNPL agreement in the future.',
  searchEircode: 'Search',
  bookingDate: 'Booking Date',
  credit: 'Credit',
  noTransactions: 'No transactions',
};
