import { camelCase } from 'change-case';
import { getT } from './framework/intl';
import { ZohoLoanProduct } from 'models/Application';

export const obfuscatePhone = (phone: string) => {
  try {
    let p = (phone || '').replace(/[^+\d]/gi, '');

    return p.substr(0, 2) + '*'.repeat(p.length - 5 || 0) + p.substr(p.length - 3, 3);
  } catch (e) {
    return '';
  }
};

export enum Currency {
  EUR = 'EUR',
}

export enum Links {
  fb = 'https://www.facebook.com/capitalflowirl/',
  ln = 'https://www.linkedin.com/company/capitalflow/',
  twitter = 'https://twitter.com/Capitalflow_IRL',
  privacyPolicy = 'https://www.capitalflow.ie/privacy-policy/',
  tos = 'https://www.capitalflow.ie/terms-and-conditions/',
}

export const formatCurrency = (value: string | number) => {
  return currencyFormats[Currency.EUR](value);
};

const currencyFormats = {
  [Currency.EUR]: (value: string | number) =>
    `€\u00a0${Number(value).toLocaleString('en-GB', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })}`,
};

export const _t = (string: string) => {
  const _t = getT();
  return _t(string);
};

export const capitalize = (s: string) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const cameliseKeys = (o: any): any => {
  if (o === Object(o) && !Array.isArray(o) && typeof o !== 'function') {
    const n: { [key: string]: any } = {};
    Object.keys(o).forEach(k => {
      n[camelCase(k)] = cameliseKeys(o[k]);
    });
    return n;
  } else if (Array.isArray(o)) {
    return o.map(i => {
      return cameliseKeys(i);
    });
  }
  return o;
};

export const translateLabels = (labels: string[]) => {
  const _t = getT();
  return labels.map(label => _t(label));
};

type CamelToSnake<T extends string, P extends string = ''> = string extends T
  ? string
  : T extends `${infer C0}${infer R}`
  ? CamelToSnake<R, `${P}${C0 extends Lowercase<C0> ? '' : ' '}${Lowercase<C0>}`>
  : P;

type CamelKeysToSnake<T> = {
  [K in keyof T as CamelToSnake<Extract<K, string>>]: T[K];
};

function camelToSnakeCase<T extends string>(key: T) {
  return key.replace(/([A-Z])/g, '_$1').toLowerCase();
}

export function camelKeysToSnakeCase<T>(object: { [K in keyof T]: T[K] }): CamelKeysToSnake<T> {
  let newObject: CamelKeysToSnake<any> = {};

  for (const camel in object) {
    newObject[camelToSnakeCase(camel)] = object[camel];
  }

  return newObject;
}

export const zohoLoanProductLabel = (loanProduct: ZohoLoanProduct): string => {
  const mapping = {
    [ZohoLoanProduct.Leasing]: _t(`loans.leasing`),
    [ZohoLoanProduct.LeasingUCGS]: _t(`loans.ucgsLeasing`),
    [ZohoLoanProduct.HirePurchase]: _t(`loans.hirePurchase`),
    [ZohoLoanProduct.HirePurchaseUCGS]: _t(`loans.ucgsHirePurchase`),
    [ZohoLoanProduct.PersonalMotorFinance]: _t(`loans.personalMotorFinance`),
  };
  return mapping[loanProduct];
};
